import jQuery from "jquery";
import 'jquery-validation';
import AirDatepicker from 'air-datepicker';

import locale_en from 'air-datepicker/locale/en';
import locale_hu from 'air-datepicker/locale/hu';
import locale_ru from 'air-datepicker/locale/ru';

import dayjs from 'dayjs';

// locale
import 'dayjs/locale/ru';
import 'dayjs/locale/hu';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore);

import Swal from 'sweetalert2';

; (function ($) {
    $(() => {
        //
        // Swal.fire({
        //     width: 450,
        //     customClass: {
        //         popup: 'swal_popup_small',
        //         confirmButton: 'button'
        //     },
        //     title: "Booking available",
        //     // text: 'Continue',
        //     confirmButtonText: 'Close',
        //     showCloseButton: true,
        //     showCancelButton: false,
        //
        // })


        let $app_locale = $('html').attr('lang');
        let airlocale;


        if($app_locale === "hu"){
            airlocale = locale_hu;
            $.extend( $.validator.messages, {
                required: "Kötelező megadni.",
                maxlength: $.validator.format( "Legfeljebb {0} karakter hosszú legyen." ),
                minlength: $.validator.format( "Legalább {0} karakter hosszú legyen." ),
                rangelength: $.validator.format( "Legalább {0} és legfeljebb {1} karakter hosszú legyen." ),
                email: "Érvényes e-mail címnek kell lennie.",
                url: "Érvényes URL-nek kell lennie.",
                date: "Dátumnak kell lennie.",
                number: "Számnak kell lennie.",
                digits: "Csak számjegyek lehetnek.",
                equalTo: "Meg kell egyeznie a két értéknek.",
                range: $.validator.format( "{0} és {1} közé kell esnie." ),
                max: $.validator.format( "Nem lehet nagyobb, mint {0}." ),
                min: $.validator.format( "Nem lehet kisebb, mint {0}." ),
                creditcard: "Érvényes hitelkártyaszámnak kell lennie.",
                remote: "Kérem javítsa ki ezt a mezőt.",
                dateISO: "Kérem írjon be egy érvényes dátumot (ISO).",
                step: $.validator.format( "A {0} egyik többszörösét adja meg." )
            } );
        }
        if($app_locale === "ru"){
            airlocale = locale_ru;
            $.extend( $.validator.messages, {
                required: "Это поле необходимо заполнить.",
                remote: "Пожалуйста, введите правильное значение.",
                email: "Пожалуйста, введите корректный адрес электронной почты.",
                url: "Пожалуйста, введите корректный URL.",
                date: "Пожалуйста, введите корректную дату.",
                dateISO: "Пожалуйста, введите корректную дату в формате ISO.",
                number: "Пожалуйста, введите число.",
                digits: "Пожалуйста, вводите только цифры.",
                creditcard: "Пожалуйста, введите правильный номер кредитной карты.",
                equalTo: "Пожалуйста, введите такое же значение ещё раз.",
                extension: "Пожалуйста, выберите файл с правильным расширением.",
                maxlength: $.validator.format( "Пожалуйста, введите не больше {0} символов." ),
                minlength: $.validator.format( "Пожалуйста, введите не меньше {0} символов." ),
                rangelength: $.validator.format( "Пожалуйста, введите значение длиной от {0} до {1} символов." ),
                range: $.validator.format( "Пожалуйста, введите число от {0} до {1}." ),
                max: $.validator.format( "Пожалуйста, введите число, меньшее или равное {0}." ),
                min: $.validator.format( "Пожалуйста, введите число, большее или равное {0}." )
            } );
        }
        if($app_locale === "en"){
            airlocale = locale_en;
        }

        // dayjs.locale($app_locale);

        var formBookingByDateAndGuestsDom = $("#multistepBookingForm");
        if ( $(formBookingByDateAndGuestsDom).length ) {

            let dp = null;
            let form = {
                dayjsFormat : "dddd, D MMMM, YYYY",
                minDate : dayjs(),
                initDate : dayjs(), //NOW
                displayedDate : dayjs(),// dayjs(form.currentDate).format(form.dayjsFormat), // Date shown in the input step 2
                year : "2024", //hidden field
                month : "10", //hidden field
                day : "8", //hidden field
                startTime : "18:00", //hidden field
                programName: '',
                numberOfPeople : 1,
                date_picker : null,
                step : 1,
                debugging : true,
                isDisableTopNav : false, //setting true or default false

                bannedDays: [],

                // Method to initialize
                init: () => {

                    form.ajaxGetBannedDay();
                    form.initDatepicker();
                    form.initSelect();
                    form.updateDatePreviewInput();

                    form.formValidate();


                    $('#multistepBookingForm').keypress(function (e) {
                        if (e.keyCode == 13) {
                            return false;
                        } else {
                            // your code here...
                        }
                    });

                    $('.booking-multistep__data-program').hide();

                    //top number buttons
                    $('.booking-multistep__form-steps_button').click(function (e){
                        e.preventDefault();
                        if(form.isDisableTopNav == false){
                            let activateTabNum = $(this).attr('data-step');
                            form.stepActivate(activateTabNum);
                        }
                    });

                    //program time select
                    $(document).on('click', '.program-card__time-intervals-item', function () {

                        if($(this).hasClass('locked')){
                            return;
                        }

                        $(document).find('.program-card').removeClass('active').addClass('disabled');//clear

                        let parent = $(this).closest('.program-card');
                        $(parent).addClass('active').removeClass('disabled');

                        let duration = $(parent).attr('data-program-duration');
                        let id = $(parent).attr('data-program-id');

                        $(document).find('.program-card__time-intervals-item').removeClass('active');//clear
                        $(this).addClass('active');

                        //set inner variable
                        form.startTime = $(this).attr('data-start-at');

                        //set duration hidden fields
                        $('#bookNowFormDuration').val(duration)
                        $('#bookNowFormProgramId').val(id)
                        $('#bookNowFormTime').val(form.startTime);




                        let title = $(parent).attr('data-program-title');
                        $('#selectedProgramTitle').html(title);

                        let img = $(parent).attr('data-program-image-url');
                        $('#selectedProgramImg').attr('src', img);

                        let svgPeople = $(parent).find('.program-card__amount_wrap_people_count').html()

                        $('#selectedProgramSvgIcons').html(svgPeople);

                        $('.booking-multistep__data-program').show();


                        //extra html
                        $('.booking_time').html($(this).html());

                    });

                    // Add event listeners to the buttons to button form navigation
                    document.getElementById('step_button_1_next').addEventListener('click', (e) => {
                        e.preventDefault();
                        if( form.validateStep_1() !== false){
                            form.stepActivate(2);
                            form.ajaxGetSessionByDateAndGuests();
                        } else {
                            form.shakeElement('step_button_1_next');
                        }
                    });

                    document.getElementById('step_button_2_prev').addEventListener('click', (e) => {
                        form.stepActivate(1)
                    });

                    document.getElementById('step_button_2_next').addEventListener('click', (e) => {
                        e.preventDefault();

                            if( form.validateStep_2() !== false){
                                form.stepActivate(3)
                            }else{
                                form.shakeElement('step_button_2_next');
                            }

                    });

                    document.getElementById('step_button_3_prev').addEventListener('click', (e) => {
                        form.stepActivate(2)
                    });


                    // Add event listeners to the next/prev buttons
                    document.getElementById('next-day-btn').addEventListener('click', (e) => {
                        e.preventDefault();
                        form.nextDay()
                    });
                    document.getElementById('prev-day-btn').addEventListener('click', (e) => {
                        e.preventDefault();
                        form.prevDay()
                    });
                },

                initDatepicker: () => {
                    //https://air-datepicker.com/docs init AirDatepicker instance
                    form.date_picker = new AirDatepicker('#mainDatepicker', {
                    // dp = new AirDatepicker('#mainDatepicker', {
                        minDate: form.initDate,
                        dateFormat: "EEEE, dd MMMM, yyyy",
                        firstDay: 1,
                        locale: airlocale,
                        autoClose: true,
                        showOtherMonths: false,
                        onSelect: ({date, formattedDate, datepicker})  => {
                            form.updateDatePreviewInput(date);

                            //todo: DRY
                            if( form.validateStep_1() !== false){
                                form.stepActivate(2);
                                form.ajaxGetSessionByDateAndGuests();
                            } else {
                                form.shakeElement('step_button_1_next');
                            }

                        }
                    });

                    //examples:
                    // dp.selectDate(dayjs('2024-11-20').format(form.dayjsFormat))
                    // form.date_picker.selectDate(dayjs('2024-11-20').format(form.dayjsFormat))
                    // dp.disableDate(['2024-10-12']);//or array

                    // form.date_picker.disableDate(form.bannedDays);
                },

                initSelect : () => {
                    const countGuests = $("#bookNowFormGuests");
                    countGuests.on('select-constructor:change', function (e) {
                        form.numberOfPeople = countGuests.val();
                    });
                },

                setMaxGuestCart: ()=> {
                    let cards = $(document).find('.program-card');
                    cards.each(function (index, value){
                        let max = $(this).attr('data-program-max-guests')
                        if(max < form.numberOfPeople){
                            $(this).addClass('maximum_limit');
                        }
                    });
                },

                // Method to update the input field with the formatted date
                //update ony second input not a calendar
                updateDatePreviewInput: (date) => {

                    form.displayedDate = dayjs(date).format(form.dayjsFormat);
                    // form.displayedDate = dayjs(date).locale($app_locale).format(form.dayjsFormat);
                    // let ddTranslated = dayjs(date).locale($app_locale).format(form.dayjsFormat);

                    //document.getElementById('date_picker_preview').value = form.displayedDate;
                    document.getElementById('date_picker_preview').value = dayjs(form.displayedDate).locale($app_locale).format(form.dayjsFormat);
                    $('.booking_date').html(dayjs(form.displayedDate).locale($app_locale).format(form.dayjsFormat))

                    form.updateVariableInput(date);

                },



                updateVariableInput: (date) => {

                    //https://day.js.org/docs/en/display/format
                    form.year = dayjs(date).format('YYYY');
                    form.month = dayjs(date).format('M'); // or MM
                    form.day = dayjs(date).format('D'); //or DD

                    $('input[name="year"]').val(form.year);
                    $('input[name="month"]').val(form.month);
                    $('input[name="day"]').val(form.day);

                    if(date){
                        let d = dayjs(date).format("YYYY-MM-DD")
                        $('#bookNowFormDate').val(d);
                    }

                },

                // AJAX function get list of available program
                ajaxGetSessionByDateAndGuests: (action, date) => {
                    $.ajax({
                        url: '/api/booking/getSessionsByDateAndGuests',
                        headers: { 'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content') },
                        type: 'GET',
                        dataType: 'html',
                        data: {
                            'year' : form.year,
                            'month' : form.month,
                            'day' : form.day,
                            'numberOfPeople' : form.numberOfPeople,
                            'lang' : $app_locale
                        },
                        beforeSend: (data) => {

                            $(".preloader").show();
                            form.resetSelectedProgramData();

                            $('#programs__container').html('');
                            $('#prev-day-btn').prop("disabled", true);
                            $('#next-day-btn').prop("disabled", true);
                        },
                        success: (response) => {
                            if(response){
                                $(".preloader").hide();
                                $('#programs__container').html(response);
                            }
                        },
                        error: (xhr) => {
                            $(".preloader").hide();
                            form.resetSelectedProgramData();
                        },
                        complete: function () {
                            $(".preloader").hide();

                            setTimeout(function (){
                                $('#prev-day-btn').prop("disabled", false);
                                $('#next-day-btn').prop("disabled", false);
                            }, 500)

                            form.setMaxGuestCart();

                        }
                    });
                },

                // AJAX get array full closed day for calendar
                ajaxGetBannedDay: () => {

                    $.ajax({
                        url: '/api/settings/getBannedDays',
                        headers: { 'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content') },
                        type: 'GET',
                        dataType: 'json',
                        beforeSend: (data) => {
                            // $('#prev-day-btn').prop("disabled", true);
                            // $('#next-day-btn').prop("disabled", true);
                            $(".preloader").show();
                        },
                        success: (response) => {
                            $(".preloader").hide();
                            let settings = $.parseJSON(JSON.stringify(response))
                            $.each(settings, function( index, val ) {
                                form.bannedDays.push(val['banned_date_formatted'])
                            });

                            form.date_picker.disableDate(form.bannedDays);
                        },
                        error: (xhr) => {
                            $(".preloader").hide();
                        },
                        complete: function () {
                            $(".preloader").hide();
                        }
                    });

                },

                // Method to move to the next day
                nextDay: () => {


                    form.displayedDate = dayjs(form.displayedDate).add(1, 'day');

                    form.updateDatePreviewInput(form.displayedDate);

                    //set date prev tab calendar for sync
                    form.date_picker.selectDate(dayjs(form.displayedDate).format(form.dayjsFormat))
                    form.ajaxGetSessionByDateAndGuests('next', form.displayedDate); // Make the AJAX call for 'next'


                    form.updateVariableInput(form.displayedDate);

                    $('.booking-multistep__data-program').hide();
                },

                // Method to move to the previous day
                prevDay: () => {


                    if( dayjs(form.minDate).isSameOrBefore(dayjs(form.displayedDate)) == true ){
                        form.displayedDate = dayjs(form.displayedDate).subtract(1, 'day');
                        form.updateDatePreviewInput(form.displayedDate);

                        //set date prev tab calendar for sync
                        form.date_picker.selectDate(dayjs(form.displayedDate).format(form.dayjsFormat))
                        form.ajaxGetSessionByDateAndGuests('prev', form.displayedDate); // Make the AJAX call for 'prev'

                        form.updateVariableInput(form.displayedDate);

                        $('.booking-multistep__data-program').hide();
                    }

                },

                resetSelectedProgramData: () => {

                    // if(form.step < 2){
                        $(document).find('.program-card').removeClass('active').removeClass('disabled');//clear
                        $(document).find('.program-card__time-intervals-item').removeClass('active');

                        // $('#bookNowFormDate').val('')
                        $('#bookNowFormDuration').val('')
                        $('#bookNowFormProgramId').val('')
                        $('#bookNowFormTime').val('');
                    // }
                },

                //move between tabs
                stepActivate : (step = 1) => {
                    form.step = step;
                    $('.booking-multistep__form-steps_button').removeClass('active');
                    $('.booking-multistep__form-steps_button[data-step="' + step + '"]').addClass('active')
                    $('.booking-multistep__form_step').removeClass('active');
                    $('.booking-multistep__form_step_' + step).addClass('active');
                },

                validateStep_1 : () => {
                    let dateField = $('#mainDatepicker').val();
                    let guestsField = $("#bookNowFormGuests").val();
                    if(dateField && guestsField){
                        return true;
                    }
                    return false;
                },

                validateStep_2 : () => {

                    let dur = $('#bookNowFormDuration').val()
                    let program = $('#bookNowFormProgramId').val()
                    let time = $('#bookNowFormTime').val();

                    if(dur && program && time){
                        return true;
                    }

                    return false;
                },

                setDuration: (duration) => {
                    $('#bookNowFormDuration').val(duration);
                },

                //for devs
                debug: (title = 'Debug: ', data) => {
                    if(form.debugging == true){
                        console.log(title, data);
                    }
                },

                //soft user alert =)
                shakeElement: (el) => {
                    el = document.getElementById(el);
                    el.classList.add('rotateable');
                    el.style.marginLeft = '10px';
                    el.style.marginRight = '-10px';

                    setTimeout(function() {
                        el.style.marginLeft = '-20px';
                        el.style.marginRight = '20px';
                        setTimeout(function() {
                            el.style.marginLeft = '0';
                            el.style.marginRight = '0';
                        }, 100);
                    }, 100);

                    return true;
                },

                selectProgramTime: () => {
                    //do some
                },

                transformErrorsForValidator: (responseErrors) => {
                    const errors = [];
                    for (let name in responseErrors) {
                        let realName = name.split('.').map((part, index) => {
                            if (index) {
                                return `[${part}]`;
                            }

                            return part;
                        }).join('');

                        errors[realName] = responseErrors[name];
                    }

                    return errors;
                },

                formValidate : () =>{
                    // Validate form
                    const $form = $("#multistepBookingForm");
                    const formValidator = $form.validate({
                        errorElement: 'div',
                        lang: $app_locale,
                        rules: {
                            name: {
                                required: true,
                                minlength: 3,
                                maxlength: 255,
                            },
                            email: {
                                required: true,
                                email: true,
                            },
                            policy: {
                                required: true
                            }
                        },
                        messages: {
                            // name: {
                            //     required: "Please enter name",
                            // },
                            // email: {
                            //     required: "Please enter valid email",
                            // },
                        },
                        highlight: function(element) {
                            $(element).closest('.input-highlight').removeClass('has-success').addClass('has-error');
                        },
                        unhighlight: function(element) {
                            $(element).closest('.input-highlight').addClass('has-success').removeClass('has-error');
                        },
                        errorPlacement(error, element) {

                            const parentWrapper = element.closest('.validate_message_wrap');
                            if (parentWrapper.length) {
                                parentWrapper.append(error);

                            } else {

                                error.insertAfter(element);
                            }

                        },
                        submitHandler: () => {
                            const url = '/api/booking/storeFormToAltegio',
                                form = $form.get(0),
                                data = new FormData(form);

                            $.ajax({
                                url,
                                data,
                                dataType: 'json',
                                processData: false,
                                contentType: false,
                                type: 'POST',
                                beforeSend: () => {
                                    $('#step_button_3_complete').prop('disabled', true);
                                    $(".preloader").show();
                                },
                                success: (response) => {
                                    $(".preloader").hide();
                                    if (response.data.paymentLink) {

                                        //complete form
                                        window.location.href = response.data.paymentLink;
                                    }
                                },
                                error: function (xhr) {
                                    $(".preloader").hide();
                                    if (xhr.responseJSON.message) {
                                        // alert();
                                        Swal.fire({
                                            width: 450,
                                            customClass: {
                                                popup: 'swal_popup_small',
                                                confirmButton: 'button'
                                            },
                                            title: xhr.responseJSON.message,
                                            // text: 'Do you want to continue',
                                            confirmButtonText: 'Close',
                                            showCloseButton: true,
                                            showCancelButton: false,

                                        })
                                    }

                                    if (422 === xhr.status && xhr.responseJSON.errors) {
                                        formValidator.showErrors(
                                            // form.transformErrorsForValidator(xhr.responseJSON.errors)
                                        );

                                        return;
                                    }
                                },
                                complete: () => {
                                    $(".preloader").hide();
                                    // enableSubmitting();
                                    $('#step_button_3_complete').prop('disabled', false);
                                }
                            });
                        }
                    });


                },


            };



            // Initialize
            form.init();



        }
    });
})(jQuery);
