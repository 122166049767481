import { menuClose } from "./functions.js";

document.addEventListener("DOMContentLoaded", () => {
  const menuBody = document.querySelector(".menu__body");
  document.addEventListener("click", (e) => {
    if (e.target.closest(".menu__link-arrow")) {
      e.preventDefault();
    }
    if (!e.target.closest(".header") && document.querySelector(".menu-open")) {
      menuClose();
    }
    if (e.target.closest(".menu__link") && window.innerWidth < 1199.98) {
      e.preventDefault();
      if (menuBody) {
        menuBody.scrollTop = 0;
      }
      e.target.closest(".menu__item").classList.add("submenu-open");
    }
    if (e.target.closest(".menu__title")) {
      e.target.closest(".menu__item").classList.remove("submenu-open");
    }
  });


  const btnSound = document.querySelector("[data-video-sound]");
  const btnsPlay = document.querySelectorAll("[data-video-play]");
  if (btnSound) {
    if (
      btnSound.closest("[data-video]").querySelector("[data-video-el]").muted
    ) {
      btnSound.classList.add("sound-off");
    }
    const videoEl = btnSound
      .closest("[data-video]")
      .querySelector("[data-video-el]");

    btnSound.addEventListener("click", () => {
      btnSound.classList.toggle("sound-off");
      videoEl.muted = !videoEl.muted;
    });
  }
  if (btnsPlay.length > 0) {
    btnsPlay.forEach((btnPlay) => {
      const videoEl = btnPlay
        .closest("[data-video]")
        .querySelector("[data-video-el]");
      btnPlay.addEventListener("click", () => {
        if (videoEl.paused) {
          btnPlay.classList.add("video-playing");
          videoEl.play();
        } else {
          videoEl.pause();
          btnPlay.classList.remove("video-playing");
        }
      });
    });
  }
});
