// import jQuery from 'jquery';

// (function ($) {
//     $(() => {
//         $('.contact-form').on('submit', function(event) {
//             event.preventDefault();

//             // Отримуємо всі дані форми
//             var formData = $(this).serialize();

//             $.ajax({
//                 url: '/order-private-card', // URL для відправки форми
//                 type: 'POST',
//                 data: formData,
//                 headers: {
//                     'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), // CSRF токен
//                 },
//                 success: function(data) {
//                     if (data.status === 'success') {
//                         alert(data.message);  // Повідомлення про успішну відправку
//                         $('.contact-form')[0].reset(); // Очищення форми
//                     } else {
//                         alert('Щось пішло не так. Спробуйте ще раз.');  // Повідомлення про помилку
//                     }
//                 },
//                 error: function(jqXHR, textStatus, errorThrown) {
//                     console.log('Помилка:', textStatus, errorThrown); // Логування помилок
//                 }
//             });
//         });
//     });
// })(jQuery);

import jQuery from 'jquery';

(function ($) {
    $(() => {
        $('.private-card-form').on('submit', function(event) {
            event.preventDefault();

            // Отримуємо вибране значення для select
            var selectedFormText = $('select[name="form[]"] option:selected').text();
            
            // Отримуємо всі інші дані форми
            var formData = $(this).serializeArray();
            
            // Додаємо поле з вибраним текстом select
            formData.push({ name: 'form', value: selectedFormText });

            $.ajax({
                url: '/private-card-request', // URL для відправки форми
                type: 'POST',
                data: $.param(formData),
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), // CSRF токен
                },
                success: function(data) {
                    if (data.status === 'success') {
                        alert(data.message);  // Повідомлення про успішну відправку
                        $('.private-card-form')[0].reset(); // Очищення форми
                    } else {
                        alert('Щось пішло не так. Спробуйте ще раз.');  // Повідомлення про помилку
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log('Помилка:', textStatus, errorThrown); // Логування помилок
                }
            });
        });

        //
        $('.franchise-form').on('submit', function(event) {
            event.preventDefault();

            // Отримуємо всі дані форми
            var formData = $(this).serialize();

            $.ajax({
                url: '/franchise-request', // URL для відправки форми
                type: 'POST',
                data: formData,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') // CSRF токен
                },
                success: function(data) {
                    if (data.status === 'success') {
                        alert(data.message);  // Повідомлення про успішну відправку
                        $('.franchise-form')[0].reset(); // Очищення форми
                    } else {
                        alert('Щось пішло не так. Спробуйте ще раз.');  // Повідомлення про помилку
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log('Помилка:', textStatus, errorThrown); // Логування помилок
                }
            });
        });

        $('.school-form').on('submit', function(event) {
            event.preventDefault();

            // Отримуємо всі дані форми
            var formData = $(this).serialize();

            $.ajax({
                url: '/school-request', // URL для відправки форми
                type: 'POST',
                data: formData,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') // CSRF токен
                },
                success: function(data) {
                    if (data.status === 'success') {
                        alert(data.message);  // Повідомлення про успішну відправку
                        $('.school-form')[0].reset(); // Очищення форми
                    } else {
                        alert('Щось пішло не так. Спробуйте ще раз.');  // Повідомлення про помилку
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    console.log('Помилка:', textStatus, errorThrown); // Логування помилок
                }
            });
        });        


    });
})(jQuery);
