import jQuery from "jquery";
import 'jquery-validation';
import CalendarControl from "./calendar";
import { dayPad } from "./days";
import selectsManager from "../plugins/selectsManager";

/** Book now page  */
; (function ($) {
    $(() => {
        if (!$('#bookNow').length) {
            return;
        }

        function disableSubmitting() {
            $('#bookNowFormSubmit').prop('disabled', true);
        }

        function enableSubmitting() {
            $('#bookNowFormSubmit').prop('disabled', false);
        }

        function fillCalendarButtons(days) {
            calendarControl.refresh();

            const calendarDate = calendarControl.getDate();

            $('.calendar__number-wrapper.calendar__choose-number').removeClass('calendar__choose-number');
            days.forEach((data) => {
                data.year = calendarDate.getFullYear();
                data.month = calendarDate.getMonth();

                // Update status of a day on the calendar
                const calendarDayWrapper = $(`.calendar__number-wrapper[data-day="${data.day}"]`);
                calendarDayWrapper.addClass('calendar__choose-number');
                calendarDayWrapper.data('data', data);
                calendarDayWrapper.on('click', function () {
                    $('.calendar__choosed-number').removeClass('calendar__choosed-number');
                    const button = $(this);
                    button.addClass('calendar__choosed-number');

                    calendarControl.setDate(button.data('day'));


                    const data = button.data('data');
                    setDate(data);
                    setDuration(data.sessions[0] ? data.sessions[0].duration : 0);
                    updateTime(data.sessions);

                    loadSessions(data.year, data.month + 1, data.day, program.val(), countOfPeople.val());
                });

                // Make today is chosen
                const calendarControlDate = calendarControl.getDate(),
                    calendarControlSimpleDate = {
                        year: calendarControlDate.getFullYear(),
                        month: calendarControlDate.getMonth(),
                        day: calendarControlDate.getDate(),
                    };

                if (calendarControlDate && areDatesEquals(data, calendarControlSimpleDate)) {
                    calendarDayWrapper.addClass('calendar__choosed-number');
                    setDate(data);
                    setDuration(data.sessions[0] ? data.sessions[0].duration : 0);
                    updateTime(data.sessions);
                    //Array.from(data.sessions).reduce
                    //updateFreePlaces(freePlaces);
                    loadSessions(data.year, data.month + 1, data.day, program.val(), countOfPeople.val());
                }
            });
        }

        function areDatesEquals(data, calendarControlDate) {
            return data.month === calendarControlDate.month
                && data.year === calendarControlDate.year
                && data.day === calendarControlDate.day;
        }

        function loadCalendarButtons(year, month, programId, numberOfPeople) {
            if (!numberOfPeople || !programId) {
                return;
            }

            disableSubmitting();

            $.ajax({
                url: '/api/booking/getByDays',
                type: 'GET',
                dataType: 'json',
                data: { programId, year, month, numberOfPeople },
                success: function (response) {
                    if (response.data) {
                        fillCalendarButtons(response.data);
                    } else {
                        alert('Empty data.')
                    }
                },
                error: function (xhr) {
                    if (xhr.responseJSON.message) {
                        console.log('xhr.responseJSON.message', xhr.responseJSON.message);
                        alert(xhr.responseJSON.message);
                    }
                },
                complete: function () {
                    enableSubmitting();
                }
            });
        }

        function loadSessions(year, month, day, programId, numberOfPeople) {
            if (!programId || !numberOfPeople) {
                return;
            }

            disableSubmitting();
            $.ajax({
                url: '/api/booking/getSessions',
                type: 'GET',
                dataType: 'json',
                data: { programId, year, month, day, numberOfPeople },
                success: function (response) {
                    if (response.data) {
                        updateTime(response.data);
                    }
                },
                error: function (xhr) {
                    if (xhr.responseJSON.message) {
                        alert(xhr.responseJSON.message);
                    }
                },
                complete: function () {
                    enableSubmitting();
                }
            });
        }

        function updateFreePlaces(freePlaces) {
            countOfPeople.empty();

            const placeholder = countOfPeople.data('placeholder');
            countOfPeople.append($(`<option value="" selected>${placeholder}</option>`));

            for (let place = 1; place <= freePlaces; place++) {
                const $option = $('<option></option>');
                $option.attr('value', place);
                $option.text(place);

                countOfPeople.append($option);
            }

            //countOfPeople.val('');

            selectsManager.selectBuild(countOfPeople.get(0));
        }

        function setDuration(duration) {
            $('#bookNowFormDuration').val(duration);
        }

        function setDate(data) {
            $('#bookNowFormDate').val(data.year + '-' + dayPad(data.month + 1) + '-' + dayPad(data.day));
        }

        function updateTime(sessions) {
            time.empty();

            const placeholder = time.data('placeholder');
            time.append($(`<option value="" selected>${placeholder}</option>`));

            sessions.forEach((session) => {
                if (!session.guests || session.busy === session.guests) {
                    return;
                }

                const $option = $('<option></option>');
                $option.attr('value', session.start);
                $option.text(`${session.start}-${session.end}`);
                $option.data('data', session);

                time.append($option);
            });

            const bookNowFormTime = time.get(0);
            selectsManager.selectBuild(bookNowFormTime);
        }

        function setFirstCountOfPeople() {
            const valuedOption = countOfPeople.children().filter((index, option) => {
                return option.value;
            });

            const value = valuedOption.first().attr('value');
            countOfPeople.val(value);
        }

        function transformErrorsForValidator(responseErrors) {
            const errors = [];
            for (let name in responseErrors) {
                let realName = name.split('.').map((part, index) => {
                    if (index) {
                        return `[${part}]`;
                    }

                    return part;
                }).join('');

                errors[realName] = responseErrors[name];
            }

            return errors;
        }

        // Attach handlers on change selects
        const countOfPeople = $("#bookNowFormGuests");
        const program = $('#bookNowFormProgramId');
        const time = $('#bookNowFormTime');

        countOfPeople.on('select-constructor:change', function () {
            if (!program.val()) {
                return;
            }

            formValidator.element('#bookNowFormGuests');
        });

        program.on('select-constructor:change', function () {
            const date = calendarControl.getDate();
            let numberOfPeople = countOfPeople.val();
            if (!numberOfPeople) {
                numberOfPeople = 1;
            }

            loadCalendarButtons(date.getFullYear(), date.getMonth() + 1, program.val(), numberOfPeople);

            formValidator.element('#bookNowFormProgramId');
        });

        time.on('select-constructor:change', function () {
            if (!program.val()) {
                return;
            }

            const selectedOption = $(this).find(':selected'),
                data = selectedOption.data('data'),
                freePlaces = data.guests - data.busy;

            updateFreePlaces(freePlaces);
            setDuration(data.duration);

            formValidator.element('#bookNowFormTime');
        });

        // Validate form
        const $form = $("#bookNowForm");
        const formValidator = $form.validate({
            rules: {
                name: {
                    required: true,
                    minlength: 5,
                    maxlength: 255,
                },
                email: {
                    required: true,
                    email: true,
                },
                comment: {
                    maxlength: 255
                }
            },
            messages: {
                name: {
                    required: "Please enter name",
                },
                email: {
                    required: "Please enter valid email",
                },
                comment: {
                    required: "Please enter a comment",
                },
            },
            errorPlacement(error, element) {
                const parentWrapper = element.closest('.popup-complex__item');
                if (parentWrapper.length) {
                    parentWrapper.append(error);
                } else {
                    error.insertAfter(element);
                }

            },
            submitHandler: () => {
                const url = '/api/booking/store',
                    form = $form.get(0),
                    data = new FormData(form);

                console.log(data)
                setInterval(function (){

                    console.log("STOP")
                    disableSubmitting();
                    $.ajax({
                        url,
                        data,
                        dataType: 'json',
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        beforeSend: () => {
                            $('#bookNowFormSubmit').prop('disabled', true);
                        },
                        success: (response) => {
                            if (response.data.paymentLink) {
                                window.location.href = response.data.paymentLink;
                            }
                        },
                        error: function (xhr) {
                            if (xhr.responseJSON.message) {
                                alert(xhr.responseJSON.message);
                            }

                            if (422 === xhr.status && xhr.responseJSON.errors) {
                                formValidator.showErrors(
                                    transformErrorsForValidator(xhr.responseJSON.errors)
                                );

                                return;
                            }
                        },
                        complete: () => {
                            enableSubmitting();
                        }
                    });

                },20000);

            }
        });

        // Update calendar button on pressed next month or previous month
        const calendarWrapper = $('#calendar');
        calendarWrapper.on('calendar.prevPressed calendar.nextPressed', function (event, { calendar }) {
            loadCalendarButtons(calendar.getFullYear(), calendar.getMonth() + 1, program.val(), countOfPeople.val());
        });

        // Creates calendar logic
        let calendarControl = new CalendarControl({
            date: null,
            templates: {
                button: $('#calendarButtonTemplate').get(0),
                prevDatesButton: $('#calendarPrevDatesButtonTemplate').get(0),
                nextDatesButton: $('#calendarNextDatesButtonTemplate').get(0),
            },
            lang: { months: calendarWrapper.data('months'), days: calendarWrapper.data('days') },
            calendarWrapper,
        });

        // Main entry point. Load calendar buttons
        const date = calendarControl.getDate();

        // Makes all days are blocked
        $('.calendar__choose-number').removeClass('calendar__choose-number');

        let numberOfPeople = countOfPeople.val();
        if (!numberOfPeople) {
            numberOfPeople = 1;
        }

        // Tries to get calendar buttons at start
        loadCalendarButtons(date.getFullYear(), date.getMonth() + 1, program.val(), numberOfPeople);
    });
})(jQuery);
