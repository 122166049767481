import { menuClose } from "../files/functions.js";
import SmoothScroll from "smooth-scroll";

export let gotoBlock = (
  targetBlock,
  noHeader = false,
  speed = 500,
  offsetTop = 0
) => {
  const targetBlockElement = document.querySelector(targetBlock);
  if (targetBlockElement) {
    let headerItem = "";
    let headerItemHeight = 0;
    if (noHeader) {
      headerItem = "header.header";
      const headerElement = document.querySelector(headerItem);
      if (!headerElement.classList.contains("_header-scroll")) {
        headerElement.style.cssText = `transition-duration: 0s;`;
        headerElement.classList.add("_header-scroll");
        headerItemHeight = headerElement.offsetHeight;
        headerElement.classList.remove("_header-scroll");
        setTimeout(() => {
          headerElement.style.cssText = ``;
        }, 0);
      } else {
        headerItemHeight = headerElement.offsetHeight;
      }
    }
    let options = {
      speedAsDuration: true,
      speed: speed,
      header: headerItem,
      offset: offsetTop,
      easing: "easeOutQuad",
    };
    // Закриваємо меню, якщо воно відкрите
    document.documentElement.classList.contains("menu-open")
      ? menuClose()
      : null;

    if (typeof SmoothScroll !== "undefined") {
      new SmoothScroll().animateScroll(targetBlockElement, "", options);
    } else {
      let targetBlockElementPosition =
        targetBlockElement.getBoundingClientRect().top + scrollY;
      targetBlockElementPosition = headerItemHeight
        ? targetBlockElementPosition - headerItemHeight
        : targetBlockElementPosition;
      targetBlockElementPosition = offsetTop
        ? targetBlockElementPosition - offsetTop
        : targetBlockElementPosition;
      window.scrollTo({
        top: targetBlockElementPosition,
        behavior: "smooth",
      });
    }
  }
};

export function pageNavigation() {
  document.addEventListener("click", pageNavigationAction);
  // Якщо підключено scrollWatcher, підсвічуємо поточний пункт меню
  document.addEventListener("watcherCallback", pageNavigationAction);

  // Основна функція
  function pageNavigationAction(e) {
    if (e.type === "click") {
      const targetElement = e.target;
      if (targetElement.closest("[data-goto]")) {
        const gotoLink = targetElement.closest("[data-goto]");
        const gotoLinkSelector = gotoLink.dataset.goto
          ? gotoLink.dataset.goto
          : "";
        const noHeader = gotoLink.hasAttribute("data-goto-header")
          ? true
          : false;
        const gotoSpeed = gotoLink.dataset.gotoSpeed
          ? gotoLink.dataset.gotoSpeed
          : 500;
        const offsetTop = gotoLink.dataset.gotoTop
          ? parseInt(gotoLink.dataset.gotoTop)
          : 0;
        gotoBlock(gotoLinkSelector, noHeader, gotoSpeed, offsetTop);
        e.preventDefault();
      }
    } else if (e.type === "watcherCallback" && e.detail) {
      const entry = e.detail.entry;
      const targetElement = entry.target;
      // Обробка пунктів навігації, якщо вказано значення navigator, підсвічуємо поточний пункт меню
      if (targetElement.dataset.watch === "navigator") {
        const navigatorActiveItem = document.querySelector(
          `[data-goto]._navigator-active`
        );
        let navigatorCurrentItem;
        if (
          targetElement.id &&
          document.querySelector(`[data-goto="#${targetElement.id}"]`)
        ) {
          navigatorCurrentItem = document.querySelector(
            `[data-goto="#${targetElement.id}"]`
          );
        } else if (targetElement.classList.length) {
          for (let index = 0; index < targetElement.classList.length; index++) {
            const element = targetElement.classList[index];
            if (document.querySelector(`[data-goto=".${element}"]`)) {
              navigatorCurrentItem = document.querySelector(
                `[data-goto=".${element}"]`
              );
              break;
            }
          }
        }
        if (entry.isIntersecting) {
          // Бачимо об'єкт
          // navigatorActiveItem ? navigatorActiveItem.classList.remove('_navigator-active') : null;
          navigatorCurrentItem
            ? navigatorCurrentItem.classList.add("_navigator-active")
            : null;
        } else {
          // Не бачимо об'єкт
          navigatorCurrentItem
            ? navigatorCurrentItem.classList.remove("_navigator-active")
            : null;
        }
      }
    }
  }
}
