import $ from "jquery";

function CalendarControl(config) {
    const calendar = new Date();

    if (config.date) {
        if (config.date.year) {
            calendar.setFullYear(config.date.year);
        }

        if (config.date.month) {
            calendar.setMonth(config.date.month);
        }

        if (config.date.day) {
            calendar.setDate(config.date.day);
        }
    }

    const calendarButtonTemplate = config.templates.button,
        calendarPrevDatesButtonTemplate = config.templates.prevDatesButton,
        calendarNextDatesButtonTemplate = config.templates.nextDatesButton;

    const calendarControl = {
        localDate: new Date(),
        prevMonthLastDate: null,
        calWeekDays: Object.values(config.lang.days),
        calMonthName: Object.values(config.lang.months),
        daysInMonth: function (month, year) {
            return new Date(year, month, 0).getDate();
        },
        firstDay: function () {
            return new Date(calendar.getFullYear(), calendar.getMonth(), 1);
        },
        lastDay: function () {
            return new Date(calendar.getFullYear(), calendar.getMonth() + 1, 0);
        },
        firstDayNumber: function () {
            return calendarControl.firstDay().getDay() + 1;
        },
        lastDayNumber: function () {
            return calendarControl.lastDay().getDay() + 1;
        },
        getPreviousMonthLastDate: function () {
            let lastDate = new Date(
                calendar.getFullYear(),
                calendar.getMonth(),
                0
            ).getDate();
            return lastDate;
        },
        displayYear: function () {
            let yearLabel = document.querySelector(".calendar .calendar-year-label");
            yearLabel.innerHTML = calendar.getFullYear();
        },
        displayMonth: function () {
            let monthLabel = document.querySelector(
                ".calendar .calendar-month-label"
            );
            monthLabel.innerHTML = calendarControl.calMonthName[calendar.getMonth()];
        },
        selectDate: function (e) {
            const dateNumber = $(e.target).find('.number-item').text();
            const value = `${dateNumber} ${calendarControl.calMonthName[calendar.getMonth()]} ${calendar.getFullYear()}`;
            const calendarDateInput = $('#calendarDate');
            calendarDateInput.val(value);
            calendarDateInput.trigger('change');

            return false;
        },
        plotSelectors: function () {
        },
        plotDayNames: function () {
            for (let i = 0; i < calendarControl.calWeekDays.length; i++) {
                document.querySelector(
                    ".calendar .calendar-body"
                ).innerHTML += `<div class="calendar__day-name">${calendarControl.calWeekDays[i]}</div>`;
            }
        },
        addButton: function (count) {
            let button = calendarButtonTemplate.content.cloneNode(true);
            button.querySelector('.number-item').dataset.num = count;
            button.querySelector('.calendar__choose-number').dataset.day = count;
            button.querySelector('.dateNumber').innerHTML = count++;
            document.querySelector(".calendar .calendar-body").append(button)
        },
        addPrevDatesButton: function () {
            let button = calendarPrevDatesButtonTemplate.content.cloneNode(true);
            document.querySelector(".calendar .calendar-body").append(button)
        },
        addNextDatesButton: function (count) {
            let button = calendarNextDatesButtonTemplate.content.cloneNode(true);
            const nextDate = button.querySelector('.next-dates');
            if (nextDate) {
                nextDate.innerHTML = count;
            }

            document.querySelector(".calendar .calendar-body").append(button)
        },
        plotDates: function () {
            document.querySelector(".calendar .calendar-body").innerHTML = "";
            calendarControl.plotDayNames();
            calendarControl.displayMonth();
            calendarControl.displayYear();
            let count = 1;
            let prevDateCount = 0;

            calendarControl.prevMonthLastDate = calendarControl.getPreviousMonthLastDate();
            let prevMonthDatesArray = [];
            let calendarDays = calendarControl.daysInMonth(
                calendar.getMonth() + 1,
                calendar.getFullYear()
            );
            // dates of current month
            for (let i = 1; i < calendarDays; i++) {
                if (i < calendarControl.firstDayNumber()) {
                    prevDateCount += 1;
                    calendarControl.addPrevDatesButton();
                    prevMonthDatesArray.push(calendarControl.prevMonthLastDate--);
                } else {
                    calendarControl.addButton(count);
                    count++;
                }
            }
            //remaining dates after month dates
            for (let j = 0; j < prevDateCount + 1; j++) {
                calendarControl.addButton(count);
                count++;
            }
            calendarControl.plotPrevMonthDates(prevMonthDatesArray);
            calendarControl.plotNextMonthDates();
        },
        attachEvents: function () {
            let dateNumber = document.querySelectorAll(".calendar .calendar__choose-number");
            for (var i = 0; i < dateNumber.length; i++) {
                dateNumber[i].addEventListener(
                    "click",
                    calendarControl.selectDate,
                    false
                );
            }
        },
        highlightToday: function () {
            let currentMonth = calendarControl.localDate.getMonth() + 1;
            let changedMonth = calendar.getMonth() + 1;
            let currentYear = calendarControl.localDate.getFullYear();
            let changedYear = calendar.getFullYear();
            if (
                currentYear === changedYear &&
                currentMonth === changedMonth &&
                document.querySelectorAll(".number-item")
            ) {
                document
                    .querySelectorAll(".number-item")
                [calendar.getDate() - 1].classList.add("calendar-today");
            }
        },
        plotPrevMonthDates: function (dates) {
            dates.reverse();
            let prevDates = document.querySelectorAll(".prev-dates");
            if (!prevDates.length) {
                return;
            }

            for (let i = 0; i < dates.length; i++) {
                if (i in prevDate) {
                    prevDate[i].textContent = dates[i];
                }
            }
        },
        plotNextMonthDates: function () {
            let childElemCount = document.querySelector('.calendar-body').childElementCount;
            //7 lines
            if (childElemCount > 42) {
                let diff = 49 - childElemCount;
                calendarControl.loopThroughNextDays(diff);
            }

            //6 lines
            if (childElemCount > 35 && childElemCount <= 42) {
                let diff = 42 - childElemCount;
                calendarControl.loopThroughNextDays(42 - childElemCount);
            }

        },
        loopThroughNextDays: function (count) {
            if (count > 0) {
                for (let i = 1; i <= count; i++) {
                    calendarControl.addNextDatesButton(i);
                }
            }
        },
        init: function () {
            calendarControl.plotSelectors();
            calendarControl.plotDates();
            let prevBtn = document.querySelector(".calendar .calendar-prev a");
            let nextBtn = document.querySelector(".calendar .calendar-next a");
            $(prevBtn).on("click", function (e) {
                e.preventDefault();
                calendar.setMonth(calendar.getMonth() - 1);
                calendarControl.plotDates();

                config.calendarWrapper.trigger('calendar.prevPressed', { calendar });

                return false;
            });
            $(nextBtn).on("click", function (e) {
                e.preventDefault();
                calendar.setMonth(calendar.getMonth() + 1);
                calendarControl.plotDates();

                config.calendarWrapper.trigger('calendar.nextPressed', { calendar });

                return false;
            });

            calendarControl.attachEvents();
        }
    };
    calendarControl.init();

    this.getDate = function () {
        return new Date(calendar);
    }

    this.setDate = function (date) {
        calendar.setDate(date);
    }

    this.refresh = function () {
        calendarControl.plotDates();
    }
}

export default CalendarControl;
