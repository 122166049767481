import "swiper/css";
import Swiper from "swiper";
import { EffectCoverflow, Navigation, Pagination } from "swiper";

function getStretch() {
  if (innerWidth > 768) {
    return "50%";
  } else return "60%";
}

document.addEventListener("DOMContentLoaded", () => {
  const reviewsSlider = document.querySelector(".reviews__slider");
  if (reviewsSlider) {
    new Swiper(reviewsSlider, {
      modules: [Navigation, EffectCoverflow, Pagination],
      slidesPerView: "auto",
      centeredSlides: true,
      loopAdditionalSlides: 2,
      loop: true,
      effect: "coverflow",
      watchSlidesProgress: true,
      coverflowEffect: {
        rotate: 0,
        stretch: getStretch(),
        depth: 100,
        modifier: 1,
        scale: 0.8,
        slideShadows: false,
      },
      navigation: {
        nextEl: ".reviews__arrow--next",
        prevEl: ".reviews__arrow--prev",
      },
      pagination: {
        el: ".reviews__pagination",
        clickable: true,
      },
      on: {
        init(swiper) {
          updateVisibleSlides(swiper);
        },
        slideChange(swiper) {
          updateVisibleSlides(swiper);
        },
      },
    });
  }
  const productSlider = document.querySelector(".product__slider");
  if (productSlider) {
    new Swiper(productSlider, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      navigation: {
        nextEl: ".product__arrow--next",
        prevEl: ".product__arrow--prev",
      },
      pagination: {
        el: ".product__pagination",
        clickable: true,
      },
      on: {
        init(swiper) {
          updateVisibleSlides(swiper);
        },
        slideChange(swiper) {
          updateVisibleSlides(swiper);
        },
      },
    });
  }
  const recommendationsSlider = document.querySelector(
    ".recommendations__slider"
  );
  if (recommendationsSlider) {
    new Swiper(recommendationsSlider, {
      modules: [Navigation, Pagination],
      slidesPerView: 3,
      watchSlidesProgress: true,
      spaceBetween: 30,
      navigation: {
        nextEl: recommendationsSlider
          .closest(".recommendations")
          .querySelector(".recommendations__arrow--next"),
        prevEl: recommendationsSlider
          .closest(".recommendations")
          .querySelector(".recommendations__arrow--prev"),
      },
      pagination: {
        el: ".recommendations__pagination",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        550: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        991: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      on: {
        init(swiper) {
          updateVisibleSlides(swiper);
        },
        slideChange(swiper) {
          updateVisibleSlides(swiper);
        },
      },
    });
  }
});

function updateVisibleSlides(swiper) {
  swiper.slides.forEach((slide) => {
    slide.classList.remove("visible-slide");
  });

  const activeIndex = swiper.activeIndex;

  swiper.slides[activeIndex - 1]?.classList.add("visible-slide");
  swiper.slides[activeIndex - 2]?.classList.add("visible-slide");
  swiper.slides[activeIndex + 1]?.classList.add("visible-slide");
  swiper.slides[activeIndex + 2]?.classList.add("visible-slide");
}

let breakpoint = window.matchMedia("(min-width:768px)");

let swiperList = [];

let breakpointChecker = function breakpointChecker() {
  if (breakpoint.matches === true) {
    if (swiperList.length > 0) {
      swiperList.forEach((item) => {
        item.destroy(true, true);
      });
    }

    return;
  } else if (breakpoint.matches === false) {
    return enableSwiper();
  }
};

let enableSwiper = function enableSwiper() {
  const sliderBenefits = document.querySelector(".benefits__slider");
  let swiperBenefitsInstance;
  if (sliderBenefits) {
    swiperBenefitsInstance = new Swiper(sliderBenefits, {
      modules: [Navigation, Pagination],
      spaceBetween: 6,
      slidesPerView: 1,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 6,
        },
        479: {
          slidesPerView: 2,
        },
      },
      pagination: {
        clickable: true,
        el: ".benefits__pagination",
      },
      navigation: {
        prevEl: ".benefits__arrow--prev",
        nextEl: ".benefits__arrow--next",
      },
    });
    swiperList.push(swiperBenefitsInstance);
  }
};

breakpoint.addListener(breakpointChecker); // kickstart   //todo: WTF is kickstart ?

breakpointChecker();
