//import "../scss/style.scss";
import "./files/script.js";
import * as Functions from "./files/functions.js";
import "./files/sliders.js";
import "./files/forms.js";
import { pageNavigation } from "./libs/scroll.js";
import { Popup } from "./libs/popup.js";

import "./files/readmore.js";

//svg-sprite
import "virtual:svg-icons-register";

import "./files/bookNow.js"; //old template booking form logic
import "./booking_form/bookNowByDateAndGuests.js"; // click by button form booking

import "./plugins/selectsManager.js";

import {Fancybox} from '@fancyapps/ui';


pageNavigation();
Functions.menuInit();
// Functions.tabs();
Functions.spollers();
Functions.menuInit();

Fancybox.bind("[data-fancybox]",{
    defaultType: 'youtube',
    backdropClick: 'close',
    closeButton: true,
    width: "100%",
    Thumbs: false,
});

try{
    const popupInstance = new Popup({});
}catch (e) {
    console.info("new Popup({}); BUG")
}



